<template>
  <div class="error-report-wrapper">
    <div class="error-report-container">
      <div class="top-part">
        <img
          :src="ButtonIcon"
          alt="Back"
          class="back-button"
          @click="$router.go(-1)"
        >
        <h4>{{ $t('error-report.machines-report.place') }}</h4>
      </div>
      <ul class="categories-list">
        <li
          v-for="hall in hallData"
          :key="hall.id"
          class="category-item"
          @click="decideHall(hall.id)"
        >
          <custom-card
            :creation-data="hall"
          >
            {{ hall.name }}
          </custom-card>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ButtonIcon from '@/assets/arrow_button.svg'
const CustomCard = () => import('@/components/GlobalComponents/CustomCard')

export default {
  name: 'SelectHall',
  components: {
    CustomCard
  },
  data () {
    return {
      ButtonIcon,
      hallData: []
    }
  },
  created () {
    this.getHallData()
  },
  methods: {
    async getHallData () {
      try {
        const { data } = await this.$axios.get('/halls/?select=id,name,description')
        this.hallData = data
      } catch (err) {
        console.error(err)
      }
    },
    decideHall (hallId) {
      console.log(this.hallData.find(hall => hall.id === hallId).name)
      this.$store.commit('ErrorReport/SET_HALLNAME', this.hallData.find(hall => hall.id === hallId).name)
      this.$router.push({
        name: 'selectType',
        params: {
          hallId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-report-wrapper {
  width: 100%;
  padding-top: 128px; // header + breadcrumbs

  div.error-report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 167px 167px 167px;

    div.top-part {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 53px;

      img.back-button {
        cursor: pointer;
        margin-right: 32px;
      }
    }

    ul.categories-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      list-style-type: none;
      margin-top: 64px;
      padding: 0;

      li.category-item {
        margin: 0 16px 16px 0;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-report-wrapper {
    position: relative;
    padding: 64px 0 100px 0;

    div.error-report-container {
      padding: 0;

      div.top-part {
        margin-top: 0;
        padding: 24px;

        img.back-button {
          position: fixed;
          bottom: 100px;
          right: 16px;
          margin: 0;
        }

        h4 {
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.5px;
        }
      }

      ul.categories-list {
        flex-direction: column;
        margin-top: 0;

        li.category-item {
          margin: 0;
          border-bottom: 1px solid $primary-lightgrey;
        }
      }
    }
  }
}
</style>
